/** @format */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import GlobalLayout from "../components/layouts/GlobalLayout";
import SiteHeader from "../components/SiteHeader/SiteHeader";
import Footer from "../components/Footer/Footer";
import Pokoj from "../components/Pokoj/Pokoj";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

import { StyledPokoje } from "../components/Pokoje/StyledPokoje";

const Pokoje = () => {
    const data = useStaticQuery(graphql`
        query Pokoje {
            allDatoCmsPokoje {
                edges {
                    node {
                        zdjeciePokoju {
                            gatsbyImageData(placeholder: TRACED_SVG)
                        }
                        opisPokoju
                    }
                }
            }
        }
    `);

    return (
        <GlobalLayout>
            <HelmetTemplate title="Pokoje" desc="Nasze pokoje" />
            <SiteHeader title="Pokoje" />
            <StyledPokoje>
                {data.allDatoCmsPokoje.edges.map((pokoj) => (
                    <Pokoj data={pokoj} key={pokoj.node.opisPokoju} />
                ))}
            </StyledPokoje>
            <Footer />
        </GlobalLayout>
    );
};

export default Pokoje;
