/** @format */

import React from "react";
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import {
    StyledPokoj,
    StyledImageWrapper,
    StyledImage,
    StyledTextWrapper,
    StyledButtonsWrapper,
    StyledShadow,
} from "./StyledPokoj";

const Pokoj = ({ data }) => {
    const image1 = getImage(data.node.zdjeciePokoju);
    return (
        <StyledPokoj>
            <StyledImageWrapper>
                <StyledImage image={image1} alt={data.node.opisPokoju} />
            </StyledImageWrapper>
            <StyledShadow>
                <StyledTextWrapper>
                    <p>{data.node.opisPokoju}</p>
                </StyledTextWrapper>
                <StyledButtonsWrapper>
                    <Link to="/galeria">
                        <button>Zobacz więcej zdjęć</button>
                    </Link>
                    <Link to="/cennik">
                        <button>Cennik</button>
                    </Link>
                </StyledButtonsWrapper>
            </StyledShadow>
        </StyledPokoj>
    );
};

export default Pokoj;
