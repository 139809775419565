/** @format */

import styled from "styled-components";

export const StyledPokoje = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 50px 80px 50px;

    @media only screen and (max-width: 767px){
        flex-direction: column;
    }
    @media only screen and (max-width: 408px){
        padding: 10px 20px 60px 20px;
    }
`;
