/** @format */

import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledPokoj = styled.div`
    width: 48%;
    max-width: 624px;
    margin-top: 54px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.12);
    @media only screen and (max-width: 767px){
        width: 100%;
        max-width: unset;
        margin-top: 34px;
    }
`;
export const StyledImageWrapper = styled.div`
    height: 364px;
    width: 100%;
`;
export const StyledImage = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
export const StyledTextWrapper = styled.div`
    width: 100%;
    padding: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 160.7%;
    text-align: center;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const StyledButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top: 1px solid rgba(0,0,0,0.1);
    > a{
        text-decoration: none;
        color: #000;
        > button {
            border-radius: 24px;
            padding: 8px 32px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 160.7%;
            text-align: center;
            border: 1px solid #000;
            background-color: #fff;
            cursor: pointer;
            transition: border-color 250ms, background-color 250ms, color 250ms;
        }
        &:hover{
            > button{
                background-color: #D95720;
                border-color: transparent;
                color: #fff;
            }
        }
    }

    @media only screen and (max-width: 954px){
        flex-direction: column;
        gap: 10px;
        > a {
            width: 100%;
            > button{
                width: 100%;
            }
        }
    }
`

export const StyledShadow = styled.div`
    
`